<template>
    <div class="content-box">
        <el-card class="my-20">
            <div class="d-flex-center">
                <div class="d-inline-flex-center is-link" @click="$nav.back()">
                    <i class="icon icon-back my-8 mr-8" />
                    <span class="color-666">{{ $t('base.back') }}</span>
                </div>
                <div class="font-16 ml-20 pl-20 mr-auto font-bold color-666 border-left">{{$t('navbar.settings')}}</div>
                <!-- <el-button type="primary" size="mini" icon="el-icon-refresh" plain @click="fatchRecord()">{{ $t('base.refresh') }}</el-button> -->
            </div>
        </el-card>
        <el-row :gutter="20" class="mb-50 color-b">
            <el-col :span="6">
                <div
                    v-for="(item, index) in navMenu" :key="index"
                    class="bg-white box-shadow border-r p-20 is-link menu-item d-flex-center"
                    :class="{ active: $route.path == item.route || $route.name == item.name}"
                    @click="onNav(item.route)"
                >
                    <i class="mr-10" :class="item.icon" />
                    <span class="mr-auto" v-text="item.name" />
                    <!-- <span class="mr-auto">{{settings[locel][index]}}</span> -->
                    <i class="el-icon-arrow-right" />
                </div>
            </el-col>
            <el-col :span="18">
                <router-view />
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // active: 0
            locel: localStorage.getItem('locale') || 'en-US',
            settings:{
                // 'zh-TW':['KYC 認證','登錄密碼','交易密碼','登錄記錄'],
                // 'en-US':['KYC Verify','Login Password','Trade Password','Login logs'],
                // 'Latin':['KYC certificationem','login password','transaction password','adnotatione record'],

                //暂时去掉交易密码
                'zh-TW':['KYC 認證','登錄密碼','登錄記錄'],
                'en-US':['KYC Verify','Login Password','Login logs'],
                'Latin':['KYC certificationem','login password','adnotatione record'],

                'Thailand':['การรับรอง KYC','รหัสผ่านเข้าสู่ระบบ','บันทึกการเข้าสู่ระบบ'],
                'Korea':['KYC 인증','로그인 암호','로그인 레코드'],
                'Japan':['KYC認証','ログインパスワード','ログオンレコード'],
            },
            navMenu: [
                {
                    icon: 'el-icon-postcard',
                    name: this.$t('settings.nav1'),
                    route: '/user/settings/verify'
                },
                {
                    icon: 'el-icon-lock',
                    name: this.$t('settings.nav2'),
                    route: '/user/settings/password'
                },
                // {
                //     icon: 'el-icon-lock',
                //     name: this.$t('settings.nav3'),
                //     route: '/user/settings/pay-password'
                // },
                // {
                //     icon: 'el-icon-tickets',
                //     name: this.$t('settings.nav4'),
                //     route: '/user/settings/login-logs'
                // }
            ]
        }
    },
    methods: {
        onNav: function (route){
            if (!route) return
            if (this.$route.path == route) return
            this.$nav.replace(route)
        }
    },
    created(){

    }
}
</script>
<style lang="scss" scoped>
.menu-item{
    border-left: 4px solid transparent;
}
.menu-item.active,
.menu-item:hover{
    border-left: 4px solid $--color-primary;
    color: $--color-primary;
}
</style>